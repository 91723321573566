import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import LeftSidebar from "./containers/LeftSidebar";
import Header from "./containers/Header";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./features/user/ResetPassword"));
const ThankYou = lazy(() => import("./features/user/ThankYou"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const Pages = lazy(() => import("./pages/protected/Pages"));
const Dashboard = lazy(() => import("./pages/protected/Dashboard"));
const Leads = lazy(() => import("./pages/protected/Leads"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/ThankYou" element={<ThankYou />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          {/* <Route path="/protected/Pages" element={<Pages />} /> */}
          <Route path="/protected/Pages" element={<Pages />} />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />
          {/* <Route
            path="/protected/Leads"
            element={
              <LeftSidebar>
                 <Header/>
                <Leads />
              </LeftSidebar>

              <div className="drawer  lg:drawer-open">
                <input
                  id="left-sidebar-drawer"
                  type="checkbox"
                  className="drawer-toggle"
                />
                <div className="drawer-content flex flex-col ">
                  <Header />
                  <Leads />
                </div>
                <LeftSidebar />
              </div>
            }
          /> */}

          <Route path="*" element={<Login />} />

          {/* <Route
            path="*"
            element={
              <Navigate to={token ? "/app/welcome" : "/login"} replace />
            }
          /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
